export const Global = {
    KEY: '234NutrecoCo4562',//clave para encryptar/desencryptar claves de userextarnal
    //API_GENERAL_URL: 'https://gesconapi.sydfast.com/api/',
    API_GENERAL_URL: 'https://gesconapidev.sydfast.com/api/',
    //API_GENERAL_URL: 'http://localhost:5000/api/', // apuntando a Azure

    //APIS COMPANIES ADMIN
    API_CONSULTACOMPANIESRUC: 'CompaniesConsulta/CompanySelectRuc',
    API_UPDATECOMPANIE:'RegisterCompany/UpdateCompaniemp',
    API_UPDATE_DATA_COMPANY: "CompanyDocument/Update",
    API_GET_DOCUMENT_BY_COMPANY_ID: "CompanyDocument/GetDocumentsId/",
    API_GET_FILE_COMPANY_ID: "CompanyDocument/GetFile/",

    API_COMPANY_GET_DOCUMENT_APPROVE: "CompanyDocument/GetDocumentstoApprove",
    API_CAN_CHANGE_COMPANY_TYPE: "CompanyDocument/GetCanChangeCompanyType/",
    API_POST_UPDATE_STATUS_REVERSE_DOCUMENT: "CompanyDocument/ReverseStatusPending/",
    API_EMPLOYEE_GET_DOCUMENT_APPROVE: "EmployeeDocument/GetDocumentstoApprove",
    API_POST_UPDATE_STATUS_REVERSE_DOCUMENT_EMPLOYEED: "EmployeeDocument/ReverseStatusPending/",
    API_VEHICLE_GET_DOCUMENT_APPROVE: "VehicleDocument/GetDocumentstoApprove",
    API_POST_UPDATE_STATUS_REVERSE_DOCUMENT_VEHICLES: "VehicleDocument/ReverseStatusPending/",
    API_COMPANY_APPROVE_DOCUMENT: "CompanyDocument/ProApproveToDoc",
    API_COMPANY_APPROVE_MULTI_DOCUMENT: "CompanyDocument/SendMailToProcessedDocs/",
    API_EMPLOYEE_APPROVE_DOCUMENT: "EmployeeDocument/ProApproveToDoc",
    API_GET_ALL_DOCUMENTS_BY_EMPLOYEE_COMPANY: "EmployeeDocument/AllDocumentsByIdCompany/",
    API_VEHICLE_APPROVE_DOCUMENT: "VehicleDocument/ProApproveToDoc",
    API_GET_ALL_DOCUMENTS_BY_VEHICLE_COMPANY: "VehicleDocument/AllDocumentsByIdVehicle/",
    API_GET_DOCUMENTS_GENERAL_COMPANY: 'CompanyDocument/Companiesid/',

    //APIS REPORTS
    API_GET_REPORTS_EMPLOYEES_BY_COMPANY:'Employee/GetReportsEmployeesByCompany/',

    //APIS COMPANIES CONTRATISTA
    API_CONSULTACOMPANIESID: 'CompaniesConsulta/PostCompanyById',//CompanyById',
    API_FILE_COMPANY: 'CompanyDocument/UploadFile/',
    API_UPDATE_COMPANY_DOCUMENTS: 'CompanyDocument/UpdateStatus',
    API_DELETE_DOCUMET: 'CompanyDocument/DeleteDocuments',
    //APIS VEHCILES
    API_FILE_VEHICLE : 'VehicleDocument/UploadFile',
    API_VEHICLE_UPDATE: 'VehicleDocument/Update',
    API_VEHICLE_UPDATE_STATUS: "VehicleDocument/UpdateStatus",
    API_GET_DOCUMENT_BY_VEHICLE_ID: 'VehicleDocument/GetDocumentsId/',
    API_GET_FILE_VEHICLE_ID: 'VehicleDocument/GetFile/',


    //APIS PREGUNTAS
    API_CONSPREGUNTA: 'Questions/All',
    API_REGPREGUNTA: 'Questions/Create',
    API_UPDATEPREGUNTAS: 'Questions/Update',
    API_UPDATESTATUS: 'Questions/UpdateStatus',
    API_CONSPREGUNTAESPECIFICA: 'Questions/GetQuestionsId',
    API_GET_QUESTIONSBYGROUP : 'Questions/GetQuestionsByGroup',

    //APIS RESPUESTAS/DETALLEPREGUNTAS
    API_CONSRESPUESTAS: 'QuestionsDetail/All',
    API_REGRESPUESTA: 'QuestionsDetail/Create',
    API_UPDATARESPUESTA: 'QuestionsDetail/Update',
    API_UPDATESTATUSRESPUESTA: 'QuestionsDetail/UpdateStatus',
    API_CONSRESPUESTAESPECIFICA: 'QuestionsDetail/GetQuestionsId',
    API_CONSRESPUESTAESPECIFICAPORPREGUNTA: 'QuestionsDetail/GetAnswerByQuestionId',


    //APIS EVALUATION
    API_CONSEVALUACION: 'Evaluation/All',
    API_REGEVALUACION: 'Evaluation/Create',
    API_UPDATEEVALUACION: 'Evaluation/Update',
    API_DESACTEVALUACION: 'RegisterGeneral/desaevaluacion',
    API_ACTIVEEVALUACION: 'RegisterGeneral/activeevaluacion',
    API_UPDATESTTUSEVALUATION:"Evaluation/UpdateStatus",
    API_UPLOAD_FILE: 'Evaluation/UploadFile',
    API_ASSIGN_QUESTIONSBYEVAL: 'Evaluation/RegisterQuestions',
    API_GET_QUESTIONSBYEVAL: 'Evaluation/GetQuestionsByEval/',
    API_GET_FILE_PARAMEVAL: "Evaluation/GetFile/",



    //APIS PARAMDOCUMENT
    API_CONSPARAMDOCUMENT: 'ParameterDocument/All',
    API_REGPARAMDOCUMEN: 'ParameterDocument/Create',
    API_UPDATEPARAMDOCUMEN: 'ParameterDocument/Update',
    API_UPDATESTATUSPARAMDOCUMEN: 'ParameterDocument/UpdateStatus',
    API_CONSESPECIFICAPARAMDOCUMEN: 'ParameterDocument/GetParamDocumentId',
    API_CONSPARAMDOCUMENTYPE: 'ParameterDocument/GetParamDocumenttype',
    API_CONSPARAMDOCUMENT_COMPANY: 'ParameterDocument/GetParamDocumentCompany',
    API_CONSPARAMDOCUMENT_EMPLOYEE: 'ParameterDocument/GetParamDocumentEmployee',
    API_CONSPARAMDOCUMENT_VEHICLE: 'ParameterDocument/GetParamDocumentVehicle',

    // APIS POR HACER
    API_GET_INFO_VIGENCIA_DOCUMENTOS_CONTRATISTAS:'ConsultasPorHacer/VigenciaDocumentosContratistas/',
    API_GET_DOCUMENTOS_PENDIENTES_CONTRATISTAS:'ConsultasPorHacer/DocumentosPendientesContratistas',
    API_GET_DOCUMENTOS_PENDIENTES_TRABAJADORES:'ConsultasPorHacer/DocumentosPendientesTrabajadoresContratistas',
    GET_INFO_TRABAJADORES_COURSES_PENDING:'ConsultasPorHacer/EvaluationsByEmployee/',
    GET_DOCUMENTS_APPROVE:"ConsultasPorHacer/DocumentosPendientesAprobar",


    //APIS COMPANYDOCUMENT
    API_ALLCOMPANIES:"CompanyDocument/Companies",
    API_ALLCOMPANIESWITHDOCUMENT:"CompanyDocument/CompanieswhitDcocumentsBySecurityUser",
    API_ALLCOMPANIESWITHDOCUMENT_TYPE:"CompanyDocument/CompanieswhitDcocumentsByUserAndType",

    //APIS AUMENTO OPORTUNIDADES USUARIOS EXTERNOS
    API_UPDATEOPORTUNITIES:"Myevaluation/Update",



    //APIS EQUIPMENTS TOOLS
    API_CREATE_TOOLS : "ListsTools/Create",
    API_UPDATE_TOOLS : "ListsTools/Update",
    API_UPDATE_STATUS_TOOLS : "ListsTools/UpdateStatus",
    API_GET_ALL_TOOLS : "ListsTools/All", //recibe el id de la compañia
    API_GET_SPECIFIC_TOOLS : "ListsTools/Get", //recibe el id de la compañia
    API_GET_ALL_TOOLS_FOR_SKRETTING : "ListsTools/AllListTool",


    //APIS EMPLOYEES
    API_REGISTEREMPLOYEE:"Employee/Create",
    API_UPDATEEMPLOYEE:"Employee/Update",
    API_STATUSEMPLOYEE:"Employee/UpdateStatus",
    API_ALLEMPLOYEE:"Employee/All",
    API_ALLEMPLOYEECOMPANYID:"Employee/Allid",
    API_ALLEMPLOYEEBYID: "Employee/GetByIdEmployee",
    API_GET_EMPLOYEE_BY_COMPANY: 'Employee/GetEmployeesAndDocumentsByCompany/',
    API_GET_EMPLOYEES_BY_COMPANY : "Employee/GetEmployeesByCompany/",
    API_GET_EMPLOYEES_ALL_COMPANY : "Employee/GetEmployeesAllCompany",
    API_FILE_EMPLOYEE: 'EmployeeDocument/UploadFile/',
    API_UPDATE_DATA_EMPLOYEE: 'EmployeeDocument/Update',
    API_EMPLOYEE_DOCUMENT_UPDATE_STATUS:'EmployeeDocument/UpdateStatus',
    API_GET_DOCUMENT_BY_EMPLOYEE_ID : 'EmployeeDocument/GetDocumentsId/',
    API_EMPLOYEE_DOCUMENT_DELETE : 'EmployeeDocument/DeleteDocuments/',

    //APIS JOBS TYPE
    API_GET_JOB_TYPE: 'ConsultasGenerales/ConsultaJobTypeActive',
    API_VERIFY_EXIST_EVAL_BY_EMPLOYEE_JOB: 'Request/ExistEvalByEmployeeId',

    //APIS GET EMPLOOYE
    API_GET_EMPLOYEE_BY_IDENTIFICATION : 'Employee/GetIdentification/',
    API_gET_EMPLOYEE_BY_IDENTIFICATIONREQUEST: 'Employee/GetEmployeesByCompanyRequest/',

    //APIS GET ACTIVE SITES COMPANY
    API_GET_ALL_SITES_ACTIVE : "ConsultasGenerales/ConsultaPlantasActivas",

    //APIS GET LEVELS BY ID
    API_GET_ALL_AREA_BY_ID : "ConsultasGenerales/AllAreaByPlanta/",

    //APIS REQUEST FROM
    API_UPDATE_ADMISSION_REQUEST: "EntryRequest/Update",
    API_CREATE_ADMISSION_REQUEST: "EntryRequest/Create",
    API_SEND_EMAIL_ADMISSION_REQUEST: "EntryRequest/SendMail",
    API_UPDATE_STATUS__ADMISSION_REQUEST: "EntryRequest/UpdateStatus",
    API_UPDATE_STATUS__EMERGENCY: "EntryRequest/UpdateEmergency",
    API_GET_ALL_REQUEST: "EntryRequest/All",
    API_GET_ALL_REQUEST_BY_COMPANY_ID: "EntryRequest/AllByCompany/",
    API_SEND_MAIL_TO_REJECT : "EntryRequest/SendMail",
    API_SEND_MAIL_TO_NOTIFIED_DURAGAS: "EntryRequest/SendMailToDuragas",
    API_UPLOAD_FILE_REQUEST: 'EntryRequest/UploadFile',
    API_UPDATE_DOCUMENT: 'EntryRequest/UpdateDocument',

    //API GENERATE QR
    API_CREATE_QR_AND_SEND_EMAIL : "EntryRequest/GenerarQR/",
    API_DOWNLOAD_QR : "EntryRequest/GetFileQR/",

    //API GET REQUEST ID
    API_GET_REQUEST_BY_ID: 'EntryRequest/GetId/',

    //API WORK AUTH
    API_CREATE_WORK_AUTH: 'WorkAuth/Create',
    API_GET_BY_ID_COMPANY_WORK_AUTH:  'WorkAuth/AllByCompany/',
    API_UPDATE_STATUS_WORK_AUTH: 'WorkAuth/UpdateStatus',
    API_UPDATE_WORK_AUTH:  'WorkAuth/Update',
    API_GET_WORK_AUTH_BY_ID: 'WorkAuthByID/',
    API_GET_ALL_WORK_AUTHS_FOR_ADMIN: 'WorkAuth/AllByCompany',


    //API ENTER OUT WORK
    API_GET_ENTER_OUT_WORK_BY_ID: 'EnterOutWorker/All_ID/',
    API_GET_ALL_ENTER_OUT_WORK: 'EnterOutWorker/All',
    API_CREATE_ENTER_OUT_WORK: 'EnterOutWorker/Create',
    API_UPDATE_ENTER_OUT_WORK: 'EnterOutWorker/Update',
    API_VALIDATE_QR_EMPLOYEE:'EnterOutWorker/VerificateQR/',

    //API COMPANIE
    API_GET_ALL_COMPANIES: 'RegisterCompany/All',

    //API REQUEST
    API_GET_ALL_REQUEST_INDUCTION_BY_ID : 'Request/AllByCompany/',
    API_GET_ALL_REQUEST_INDUCTION : 'Request/All',
    API_GET_FILE: 'Request/GetFile/',

    //API DOCUMENTS BY EMPLOYEE
  API_GET_DOCUMENTS_BY_ID : 'GetDocumentsId/',
  API_GET_DOCUMENTS_BY_ID_RPT : 'CompanyDocument/GetDocumentsId/',


    API_GET_DOCUMENTS_ALL : 'EmployeeDocument/All',
    API_GET_FILE_EMPLOYEE_ID: "EmployeeDocument/GetFile/",
    API_GET_DOCUMENTS_ALL_BY_COMPANYID : 'EmployeeDocument/AllDocumentsByIdCompany/',
    API_REPORT_ENTRY_REQUEST: 'EntryRequest/Report',
    API_REPORT_REQUEST: 'Request/Report',



    API_GET_UNSAFEACT : 'UnsafeAct/All',
    API_GET_UNSAFEACTBYID : 'UnsafeAct/UnsafeActById/',
    API_UPDATE_UNSAFEACT_STATUS : 'UnsafeAct/UpdateStatus',
    API_UPDATE_UNSAFEACT: 'UnsafeAct/Update',
    API_CREATE_UNSAFEACT: 'UnsafeAct/Create',
    API_UPLOAD_FILE_UNSAFEACT: 'UnsafeAct/UploadFile',


    API_CREATE_ACCIDENTEINCIDENT: 'AccidentIncident/Create',
    API_UPDATE_ACCIDENTEINCIDENT: 'AccidentIncident/Update',
    API_UPDATESTATUS_ACCIDENTEINCIDENT: 'AccidentIncident/UpdateStatus',
    API_GET_ACCIDENTEINCIDENT: 'AccidentIncident/All',
    API_GET_ACCIDENTEINCIDENTBYID: 'AccidentIncident/AccidentIncidentId/',
    API_UPLOAD_FILE_ACCIDENTEINCIDENT: 'AccidentIncident/UploadFile',
    API_UPLOAD_FILE_BETTER: 'GetsBetter/UploadFile',

    API_GET_SEVERITY_TYPE_BY_ID: 'SystemParameter/GetSystemParameterField/',

    API_CREATE_BETTER: "GetsBetter/Create",
    API_UPDATE_BETTER: "GetsBetter/Update",
    API_ALL_BETTER: "GetsBetter/All",
    API_ID_BETTER: "GetsBetter/GetsBetterId/",
    API_UPDATESTATUS_BETTER: "GetsBetter/UpdateStatus",
    API_GET_BETTER_BY_USER: "GetsBetter/GetsBetterIdUser/",


    API_CREATE_PLANACTION: "ActionPlan/Create",
    API_UPDATE_PLANACTION: "ActionPlan/Update",
    API_ALL_PLANACTION: "ActionPlan/All",
    API_ID_PLANACTION: "ActionPlan/ActionPlanId/",
    API_UPDATESTATUS_PLANACTION: "ActionPlan/UpdateStatus",
    API_GET_PLANACTION_BY_USER: "ActionPlan/ActionPlanByIdUser/",


    API_CREATE_UNSAFECONDITION: "UnsafeCondition/Create",
    API_UPDATE_UNSAFECONDITION: "UnsafeCondition/Update",
    API_ALL_UNSAFECONDITION: "UnsafeCondition/All",
    API_ID_UNSAFECONDITION: "UnsafeCondition/UnsafeConditionId/",
    API_UPDATESTATUS_UNSAFECONDITION: "UnsafeCondition/UpdateStatus",
    API_GET_UNSAFECONDITION_BY_USER: "UnsafeCondition/UnsafeConditionIdUser/",
    API_UPLOAD_FILE_UNSAFECONDITION: 'UnsafeCondition/UploadFile',
    API_UNSAFECONDITION_MAIL: 'UnsafeCondition/SendMail',


    API_UNSAFEACT_FOULS:  'UnsafeAct/ReviewFauls/',
    API_UNSAFE_SEND_MAIL: 'UnsafeAct/SendMail',



    API_PARAM_GENERAL_ALL: 'SystemParameter/AllField',
    API_PARAM_GENERAL_BY_TYPE: 'SystemParameter/ViewField/',
    API_PARAM_GENERAL_UPDATE: 'SystemParameter/Update',
    API_PARAM_GENERAL_CREATE: 'SystemParameter/Create',
    API_PARAM_GENERAL_UPDATE_STATUS: 'SystemParameter/UpdateStatus',
    API_PARAM_GENERAL_UPDATE_GENERAL: 'SystemParameter/UpdateField',


    // URL NUEVAS DURAGAS
    API_GET_ALL_PROFILES: 'Profile/All',
    API_GET_PROFILE_BY_ID: 'Profile/ById/',
    API_CREATE_PROFILE: 'Profile/RegisterProfile',
    API_UPDATE_PROFILE: 'Profile/Update',
    API_UPDATE_STATUS_PROFILE: 'Profile/UpdateStatus',

    API_GET_MAIAEMAILADDRESS: 'SystemParameter/GetMaiaMail',



    API_GET_ALL_PAGES : 'Pages/All',


    API_GET_ALL_MENU : 'Menu/All',

    API_GET_ALL_PERMISSIONS: 'Permission/All',
    API_CREATE_PERMISSION : 'Permission/CreatePermission',
    API_GET_PERMISSIONS_BY_PROFILE_ID : 'Permission/ByProfileId/',
    API_UPDATE_PERMISSION : 'Permission/Update',

    API_GET_ALL_USER_INTERNAL: 'UserInternal/All',
    API_GET_USER_INTERNAL_BY_ID: 'UserInternal/userById/',
    API_CREATE_USER_INTERNAL: 'UserInternal/RegisterUser',
    API_UPDATE_ALL_USER_INTERNAL: 'UserInternal/UpdateAll',
    API_UPDATE_USER_PASSWORD_INTERNAL: 'UserInternal/UpdateUP',
    API_UPDATE_STATUS_USER_INTERNAL: 'UserInternal/UpdateStatus',



    API_GET_ALL_MENUS: 'Menu/All',
    API_GET_PAGES_BY_MENUID: 'Pages/PagesByMenu/',
    API_GET_ALL_MODULES: 'Pages/All',




    API_GET_QUESTION_EVAL_ID: 'Questions/GetQByEvaluationId/',
    API_CREATE_EVALUATION: 'Myevaluation/Create',
    API_SEARCH_REPETITIONS: 'Myevaluation/RepetitionsByEvaluation/',
    API_GET_EMPLOYEES_BY_EVALUATION: 'Evaluation/EvaluationsByEmployee',
    API_GET_EVALS_BY_TYPE_JOB: 'Evaluation/AllEvalsByTypeJob/',
    API_GET_EVALUATION_BY_COMPANY: 'Evaluation/EvaluationsByCompany/',


    API_GET_JOBS_BY_TYPE_EMPLOYEE: 'ConsultasGenerales/ConsultaJobByTypeEmployee/',



    API_GET_ENTRY_REQUEST_BY_EMPLOYEE: "EntryRequest/GetERequestByEmployee/",

    //APIS TIPO DE TRABAJO
    API_CONSULTAJOBTYPE: 'ConsultasGenerales/ConsultaJobType',
    API_REGISTROJOBTYPE: 'RegisterGeneral/RegisterJobType',
    API_UPDATEJOBTYPE: 'RegisterGeneral/UpdateJobType',
    API_DELETEJOBTYPE: 'RegisterGeneral/DeleteJobType',
    API_ACTIVARJOBTYPE: 'RegisterGeneral/ActiveJobType',
    API_CONSULTAJOBTYPEACTIVE: 'ConsultasGenerales/ConsultaJobTypeActive',
    API_DROPJOBTYPE: 'RegisterGeneral/DeleteJobType',

    //APIS PLANTA
    API_CONSULTAPLANTA: 'ConsultasGenerales/ConsultaPlantas',
    API_REGISTROPLANTA: 'RegisterGeneral/RegisterPlanta',
    API_UPDATEPLANTA:   'RegisterGeneral/UpdatePlanta',
    API_DELETEPLANTA:   'RegisterGeneral/DeletePlanta',
    API_ACTIVARPLANTA:  'RegisterGeneral/ActivarPlanta',

     //APIS AREA DE PLANTA
     API_CONSULTASECCIONPLANTA: 'ConsultasGenerales/ConsultaSeccionPlanta',
     API_REGISTROSECCIONPLANTA: 'RegisterGeneral/RegisterSeccionPlanta',
     API_UPDATESECCIONPLANTA: 'RegisterGeneral/UpdateSeccionPlanta',
     API_DELETESECCIONPLANTA: 'RegisterGeneral/DeleteSeccionPlanta',
     API_ACTIVARSECCIONPLANTA: 'RegisterGeneral/ActiveSeccionPlanta',

     //APIS PARAMETROS GLOBALES
    API_CONPARAM: 'ConsultasGenerales/ConsultaParam',
    API_REGISTROPARAM: 'RegisterGeneral/RegisterParam',
    API_REGISTERPARAMDETAIL: 'RegisterGeneral/RegisterParamDetails',
    API_UPDATEPARAMGENERIC: 'RegisterGeneral/UpdateParam',
    API_CONDETAILS: 'ConsultasGenerales/ConsultaParamDetail',
    API_UPDATEPARAMDETAIL: 'RegisterGeneral/UpdateParamDetails',
    API_DELETEPARAMGENERIC: 'RegisterGeneral/deactivateParam',
    API_ACTIVEPARAMGENERIC: 'RegisterGeneral/ActiveParam',
    API_ACTIVEPARAMGENERICDETAIL: 'RegisterGeneral/activeParamDetails',
    API_DELETEPARAMGENERICDETAIL: 'RegisterGeneral/deactivateParamDetails',

    //APIS PARAMETROS DOCUMENTOS
    API_CONSUDOCUJOB: 'ConsultasGenerales/ConsultaDocuJobType',
    API_REGISTERDOCUJOB: 'RegisterGeneral/RegisterParamDocuJob',
    API_UPDATEDOCUJOB: 'RegisterGeneral/UpdateParamDocuJob',
    API_DELETEDOCUJOB: 'RegisterGeneral/deleteParamDocuJob',
    API_ACTIVEDOCUJOB: 'RegisterGeneral/activeParamDocuJob',
    API_CONSUDOCU: 'ConsultasGenerales/ConsultaDocumento',
    API_REGISTERDOCU: 'RegisterGeneral/RegisterDocu',
    API_UPDATEDOCU: 'RegisterGeneral/UpdateParamDocu',
    API_DELETEDOCU: 'RegisterGeneral/deleteParamDocu',
    API_ACTIVEDOCU: 'RegisterGeneral/activeParamDocu',

     //API TIPIFICACION FALTA
     API_CONSULTATYPEFALTA:'ConsultasGenerales/ConsultaParamFoul',
     API_REGISTERTYPEFOUL:'RegisterGeneral/RegisterTypeFouls',
     API_UPDATETYPEFOUL:'RegisterGeneral/UpdateTypefouls',
     API_DESACTIVETYPEFOUL:'RegisterGeneral/desactivetypefoul',
     API_ACTIVETYPEFOUL:'RegisterGeneral/activetypefoul',
     API_CONSULTAFALTADETAIL:'ConsultasGenerales/ConsultaFoul',
     API_REGISTERFOUL:'RegisterGeneral/RegisterFouls',
     API_UPDATEFOUL:'RegisterGeneral/Updatefouls',
     API_DESACTIVEFOUL:'RegisterGeneral/desactivefoul',
     API_ACTIVEFOUL:'RegisterGeneral/activefoul',
     API_SANCION:'ConsultasGenerales/ConsultaSancion',
     API_REGISTERSANCION:'RegisterGeneral/RegisterSancion',
     API_UPDATESANCION:'RegisterGeneral/Updatesancion',
     API_DESACTIVESANCION:'RegisterGeneral/desactivesancion',
     API_ACTIVESANCION:'RegisterGeneral/activesancion',

    //APIS SUBCONTRATISTA
    API_CONSULTASUBCOMPANIE: 'CompaniesConsulta/SubCompanySelect',
    API_CONSULTASUBCOMPANIEJOB: 'CompaniesConsulta/SubCompanyJobSelect',
    API_REGISTERJOBSUBCOM: 'RegisterSubCompany/RegisterSubCompanyJob',
    API_UPDATESUBJOBCOM: 'RegisterSubCompany/UpdateSubCoJob',
    API_ACTIVESUBJOBCOM: 'RegisterSubCompany/activeSubCoJob',
    API_DESACTIVESUBJOBCOM: 'RegisterSubCompany/deactivateSubCoJob',
    API_CREATESUBCOM: 'RegisterSubCompany/SubCompanyCreate',
    API_DESACTIVESUBCOM: 'RegisterSubCompany/deactivateSubCo',
    API_ACTIVESUBCOM: 'RegisterSubCompany/activeSubCo',
    API_CONSULTASUBCOMPANIECI: 'CompaniesConsulta/SubCompanySelectCi',
    API_UPDATESUBCOMPANY: 'RegisterSubCompany/SubCompanyUpdate',

    //APIS COMPANIES ADMIN
    API_REGISTEREMP: 'RegisterCompany/RegisterCompanyAdmin',
    API_CONSULTACOMPANIES: 'CompaniesConsulta/CompanySelect',
    API_CONSULTAEMP: 'ConsultasGenerales/ConsultaCompania',
    API_UPDATEEMP: 'RegisterCompany/UpdateCompani',
    API_DESATEEMP: 'RegisterCompany/deactivateCo',
    API_ACTIVETEEMP: 'RegisterCompany/activateCo',
    API_SUBCOMPANIEBYCOMPIE: 'CompaniesConsulta/SubCompanyByIDCompany',
    API_UPDATECOMPAEMP: 'RegisterCompany/UpdateCompaniemp',
    API_CONSDOCUMENEMP: 'CompaniesConsulta/ConsultaDocumentobyEmp',
  API_GET_COMPANY_BY_ID:"RegisterCompany/byId/",

        //API DE COMUNICACION
    API_CONSTIPOCOMUNICADO: 'ConsultasGenerales/ConsultaTipoInfo',
    API_REGISTERTYPEINFO: 'RegisterGeneral/RegisterTypeInfo',
    API_UPDATETYPEINFO: 'RegisterGeneral/UpdateTypeInfo',
    API_DESACTIVETYPEINFO: 'RegisterGeneral/desactiveTypeInfo',
    API_ACTIVETYPEINFO: 'RegisterGeneral/activeTypeInfo',
    API_CONSINFO: 'ConsultasGenerales/ConsultaInfo',
    API_CONSULINFOALL: 'ConsultasGenerales/ConsultaInfoAll',
    API_REGISTERINFO:'RegisterGeneral/RegisterInfo',
    API_UPDATEINFO:'RegisterGeneral/UpdateInfo',
    API_DESACTIVEINFO:'RegisterGeneral/desactiveInfo',
    API_ACTIVEINFO:'RegisterGeneral/activeInfo',

    //API DE PREGUNTAS
    //TIPO DE PREGUNTAS
    API_CONSTIPOPREGUNTA: 'ConsultasGenerales/ConsultaTipoPregunta',
    API_REGTIPOPREGUNTA: 'RegisterGeneral/Registertipopregunta',
    API_UPDATEPREGUNTA: 'RegisterGeneral/Updatetipopregunta',
    API_DESACTIVEPREGUNTA: 'RegisterGeneral/desactivepregunta',
    API_ACTIVEPREGUNTA: 'RegisterGeneral/activepregunta',

    API_DESACTIVEPREGUNTAS: 'RegisterGeneral/desactivepreguntas',
    API_ACTIVEPREGUNTAS: 'RegisterGeneral/activepreguntas',
    API_CONSPREGUNTADETALLE: 'ConsultasGenerales/ConsultaDetallePregunta',
    API_REGIPREGUNTADETALLE: 'RegisterGeneral/RegisterDetallePregunta',
    API_UPDATEPREGUNTADETALLE: 'RegisterGeneral/Updatepreguntadetalle',
    API_DESACTIVEPREGUNTADETALLE: 'RegisterGeneral/desapreguntadetalle',
    API_ACTIVEPREGUNTADETALLE: 'RegisterGeneral/activepreguntadetalle',

    //API VEHICULO POR CONTRATISTA
    API_CONSULTALL: 'Vehicle/All',
    API_CONSULTAVEHICLE: 'Vehicle/VehicleByIDCompany/',
    API_CREATEVEHICLE: 'Vehicle/VehicleCreate',
    API_UPDATEVEHICLE: 'Vehicle/VehicleUpdate',
    API_DESACTIVEVEHICLE: 'Vehicle/DeactivateVehicle',
    API_ACTIVEVEHICLE: 'Vehicle/ActiveVehicle',
    API_UPDATE_STATUS_VEHICLE: 'Vehicle/UpdateStatus',
    API_REPORTS_VEHICLE_COMPANY: 'Vehicle/GetReportsVehiclesByCompany/',

    API_RESET_PASSWORD: 'Login/ResetPassword',

    API_EXIST_EMPLOYEE : 'Employee/ExistEmployee/',

    // API PARA OBTENER DOCUMENTOS DE VEHICULOS
    API_CONSUDOC_VEHICLE: 'ParameterDocument/GetParamDocumentVehicle',

    API_GET_ENTRY_REQUEST_EMPLOYEES: 'EnterOutWorker/GetAllEmployeeQr',
    // API CERTIFIED
    API_GET_CERTIFIED: 'Myevaluation/DataCertified',
    API_GET_IMAGE_SIGNATURE: 'Myevaluation/GetSignatureImage',

    //AlcoholCheck
    API_CREATE_ALCOHOLCHECK:"AlcoholCheck/Create",
    Menus : {
        Formularios: 2,
        Procesos: 3,
        Excelencia: 4,
        Mantenimiento: 5,
        Documentos: 7,
        Reportes: 8
    },

    ordinal_numbers: [
        'ERA',
        'DA',
        'ERA',
        'TA',
        'TA',
        'TA',
        'TA',
        'TA',
        'NA',
        'MA'
    ],


    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    //Datos que se usaban en HA
    pdi_filters: [
    		'PA_ANTE',
    		'PA_CONTE',
    		'PA_DEFO',
    		'PA_DEND',
    		'PA_ECTO',
    		'PA_GAME',
    		'PA_GREG',
    		'PA_NECR',
    		'PA_NEMA',
    		'PA_NIVE',
    		'PA_TEXT',
    		'PA_UROP'
		],
    sapproval_filters: ['SAM_01'],
    sampling_status: [
        { id:1, name:'PENDIENTE', approv_actions:['take'], samp_actions:['edit', 'delete'], origid: 75 },
        { id:2, name:'RECIBIDA', approv_actions:['assign', 'reject', 'overdue'], samp_actions:[], origid: 466 },
        { id:3, name:'ASIGNADO', approv_actions:['reset', 'reject', 'overdue'], samp_actions:[], origid: 76 },
        { id:4, name:'EN PROGRESO', approv_actions:['overdue'], samp_actions:['download'], origid: 77 },
        { id:5, name:'TERMINADO', approv_actions:['approve', 'reject', 'download', 'overdue'], samp_actions:['download'], origid: 89 },
        { id:6, name:'RECHAZADA', approv_actions:[], samp_actions:[], origid: 78 },
        { id:7, name:'APROBADO', approv_actions:['download', 'overdue'], samp_actions:['download', 'followup'], origid: 90 },
        { id:8, name:'OFFLINE', approv_actions:[], samp_actions:['offline'], origid: 0 }
    ],

    ID_COMPANY_SKRETTING : 30,


};
